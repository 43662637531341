/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        var headerTop = $('.navbar-wrapper').offset().top;
        var headerBottom = headerTop + 300; // Sub-menu should appear after this distance from top.
        
        $(window).scroll(function () {
            var scrollTop = $(window).scrollTop(); // Current vertical scroll position from the top
            if (scrollTop > headerBottom) { // Check to see if we have scrolled more than headerBottom
                if (($(".navbar-on-scroll").is(":visible") === false)) {
                    $('.navbar-on-scroll').fadeIn('slow');
                    $('.navbar-on-scroll').addClass("fixed-top");
                }
            } else {
                if ($(".navbar-on-scroll").is(":visible")) {
                    $('.navbar-on-scroll').hide();
                    $('.navbar-on-scroll').removeClass("fixed-top");
                }
            }
        }); 
        
        // // init Isotope
        // var $grid = $('.grid').isotope({
        //   itemSelector: '.element-item',
        //   layoutMode: 'fitRows'
        // });
        // console.log('$grid');
        // // filter functions
        // var filterFns = {
        //   // show if number is greater than 50
        //   numberGreaterThan50: function() {
        //     var number = $(this).find('.number').text();
        //     return parseInt( number, 10 ) > 50;
        //   },
        //   // show if name ends with -ium
        //   ium: function() {
        //     var name = $(this).find('.name').text();
        //     return name.match( /ium$/ );
        //   }
        // };

        // // bind filter on radio button click
        // $('.filters').on( 'click', 'input', function() {
        //   // get filter value from input value
        //   var filterValue = this.value;
        //   // use filterFn if matches value
        //   filterValue = filterFns[ filterValue ] || filterValue;
        //   $grid.isotope({ filter: filterValue });
        // });
        
        Isotope.Item.prototype._create = function() {
          // assign id, used for original-order sorting
          this.id = this.layout.itemGUID++;
          // transition objects
          this._transn = {
            ingProperties: {},
            clean: {},
            onEnd: {}
          };
          this.sortData = {};
        };

        Isotope.Item.prototype.layoutPosition = function() {
          this.emitEvent( 'layout', [ this ] );
        };

        Isotope.prototype.arrange = function( opts ) {
          // set any options pass
          this.option( opts );
          this._getIsInstant();
          // just filter
          this.filteredItems = this._filter( this.items );
          // flag for initalized
          this._isLayoutInited = true;
        };

        // layout mode that does not position items
        Isotope.LayoutMode.create('none');

        // init Isotope
        var $grid = $('.downloads-grid').isotope({
          itemSelector: '.download-item',
          layoutMode: 'none',
         
          columnWidth: '.ri-grid-sizer',
          percentPosition: true,
          transitionDuration: 0,
          horizontalOrder: true,
          resize: true,
          

          // layoutMode: 'fitRows'
        });
        
        // filter functions
        var filterFns = {
          // show if number is greater than 50
          numberGreaterThan50: function() {
            var number = $(this).find('.number').text();
            return parseInt( number, 10 ) > 50;
          },
          // show if name ends with -ium
          ium: function() {
            var name = $(this).find('.name').text();
            return name.match( /ium$/ );
          }
        };

        // bind filter on radio button click
        $('.filters').on( 'click', 'input', function() {
          // get filter value from input value
          console.log(this.value);
          var filterValue = this.value;
          // use filterFn if matches value
          filterValue = filterFns[ filterValue ] || filterValue;
          $grid.isotope({ filter: filterValue });
        });

       
        $('.btn-accordion').click(function(){
          
          $(".slick-slider").slick("refresh");
          
          $(this).toggleClass("color-red");

          $(this).closest(".card-wrap").toggleClass("bg-white");
          $(this).closest(".card-wrap").toggleClass("active");

        });

       $('.slider-text').not('.slick-initialized').slick({
          centerMode: false,
          slidesToShow: 3,
          adaptiveHeight: false,
          arrows: false,
          dots: true,
          slidesToScroll: 1,
          nextArrow: '.slider-text-next',
          prevArrow: '.slider-text-previous',
          responsive: [
           {
             breakpoint: 992,
             settings: { 
               slidesToShow: 1,
               centerMode: false,
               arrows: false,
             }
           }
         ]
        });

       $('.slider-product').not('.slick-initialized').slick({
          centerMode: true,
          centerPadding: '180px',
          slidesToShow: 1,
          nextArrow: '.slider-product-next',
          prevArrow: '.slider-product-previous',
          adaptiveHeight: false,
          arrows: true,
          dots: true,
          slidesToScroll: 1,
          responsive: [
           {
             breakpoint: 768,
             settings: { 
               slidesToShow: 1,
               centerMode: false,
             }
           }
         ]
        });

       $('.slick-slider').not('.slick-initialized').slick({
         prevArrow: '<div class="default-slider__button default-slider__button--previous"><span class="fa fa-angle-left">PREV</span><span class="sr-only">Prev</span></div>',
         nextArrow: '<div class="default-slider__button default-slider__button--next"><span class="fa fa-angle-right">NEXT</span><span class="sr-only">Next</span></div>',
         /*adaptiveHeight: true,*/
         slidesToShow: 1,
         dots: false,
         arrows: true,
         centerMode: false,
         responsive: [
           {
             breakpoint: 768,
             settings: { 
               slidesToShow: 1
             }
           }
         ]
       });

       $('.slick-slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
        
        const position = $('.slick-active').height() / 2 - 31.141;
        $('.default-slider__button').css('top', position);
        
       });

       // $('.slick-slider').on('init', function(event, slick, currentSlide, nextSlide){
        // console.log('init');
        
        const position = $('.slick-active').height() / 2 - 31.141;
        // console.log('position',position);
      $('.default-slider__button').css('top', position);
       // });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
